export const rules = {
  usernumber: [
    { required: true, message: "请输入员工工号", trigger: "blur" },
    {
      validator: function (rule, value, callback) {
        const reg = /^[A-Za-z0-9-]+$/;
        if (value && !reg.test(value)) {
          callback(new Error("只支持数字+字母和-字符"));
        }
        callback();
      },
    },
  ],
  username: [
    { required: true, message: "请输入账号", trigger: "blur" },
    {
      validator: (rule, value, callback) => {
        let reg = new RegExp(
          "[`~!%@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）|{}【】‘；：”“'\"。，、？]"
        );
        if (reg.test(value)) {
          return callback("不能输入该特殊字符");
        } else {
          return callback();
        }
      },
    },
  ],

  password: [{ required: true, message: "请输入密码" }],
  remark: [{ required: true, message: "备注不能为空", trigger: "blur" }],
  select: [{ required: true, message: "员工不能为空", trigger: "blur" }],
  code: [{ required: true, message: "账号不能为空", trigger: "change" }],
  employeeId: [{ required: true, message: "员工不能为空", trigger: "blur" }],
  name: [
    { required: true, message: "角色名不能为空", trigger: "blur" },
    { min: 2, max: 10, message: "角色名在2个至10个之间", trigger: "blur" },
  ],
  accountId: [{ required: true, message: "账号不能为空", trigger: "blur" }],
  state: [{ required: true, message: "状态不能为空", trigger: "blur" }],
  roleIds: [{ required: true, message: "角色不能为空", trigger: "change" }],
  userbrach: [{ required: true, message: "请选择部门", trigger: "change" }],

  //登录
  usermobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern:
        /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
      message: "手机号码格式不正确,请检查",
    },
  ],
  useremail: [
    {
      /* eslint-disable */
      pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
      message: "请输入正确的邮箱",
      trigger: "blur",
    },
  ],

  // 注册/忘记密码
  tradeUsername: [
    { required: true, message: "请输入您的用户名" },
    {
      validator: (rule, value, callback) => {
        let reg = new RegExp(
          "[`~!%@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）|{}【】‘；：”“'\"。，、？]"
        );
        if (reg.test(value)) {
          return callback("不能输入该特殊字符");
        } else {
          return callback();
        }
      },
    },
  ],
  tradePhone: [
    { required: true, message: "请输入您的手机号" },
    {
      pattern:
        /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
      message: "手机号码格式不正确,请检查",
    },
  ],
  tradePassword: [
    { required: true, message: "请输入您的密码" },
    {
      min: 8,
      max: 32,
      message: "密码至少8位，至多32位",
      trigger: "blur",
    },
    // {
    //   pattern: /^[A-Z][A-z0-9]*$/,
    //   message: "首字符必须为大写字母",
    // },
  ],
  tradePasswordagain: [
    { required: true, message: "请再次输入您的密码" },
    {
      min: 8,
      max: 32,
      message: "密码至少8位，至多32位",
      trigger: "blur",
    },
    // {
    //   pattern: /^[A-z0-9]*$/,
    //   message: "首字符必须为大写字母",
    // },
    {
      validator: (rule, value, callback) => {
        if (!value) {
          return callback("请再一次输入密码");
        } else if (value !== this.form.tradePassword) {
          return callback("两次密码不一致");
        } else {
          return callback();
        }
      },
    },
  ],

  //样式描述
  description: [
    {
      required: true,
      message: "描述不能为空",
      trigger: "blur",
    },
  ],
  status: [
    {
      required: true,
      message: "状态不能为空",
      trigger: "blur",
    },
  ],
  //新增账号
  org: [{ required: true, message: "请选择组织", trigger: "blur" }],

  systemname: [
    { required: true, message: "系统名不能为空", trigger: "blur" },
    { min: 3, max: 10, message: "系统名在3个至10个之间", trigger: "blur" },
  ],
  imageUrl: [{ required: true, message: "LOGO不能为空", trigger: "change" }],
  bgimageUrl: [{ required: true, message: "背景不能为空", trigger: "change" }],
  companyname: [
    { required: true, message: "企业名称不能为空", trigger: "blur" },
  ],
  // 权限
  labelName: [
    { required: true, message: "权限组名称不能为空", trigger: "blur" },
    {
      pattern: /^(?!_|[0-9])[a-zA-Z0-9_\u4e00-\u9fa5]{4,50}$/,
      message:
        "权限组格式不正确，仅支持中文、英文、数字、下划线（_），且只能以英文或中文开头，4~50个字符。",
    },
  ],
};

export const codeRule = {
  phone: [
    { required: true, message: "请输入手机号" },
    {
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      message: "请输入正确的手机号",
      trigger: "change",
    },
  ],
  code: [{ required: true, message: "请输入验证码" }],
};
