<template>
  <div class="content">
    <PublicHeader />
    <div class="BG">
      <div class="content">
        <img class="logos" :src="imgs" alt="" />
        <div class="box">
          <div class="header">
            <span class="body">登录</span>
            <a @click="registerRoute">
              立即注册
              <a-icon type="right" />
            </a>
          </div>
          <div>
            <a-tabs class="textleft" default-active-key="1" @change="handleTab">
              <a-tab-pane key="1" tab="账号登录">
                <div class="login-box">
                  <a-form-model
                    ref="login"
                    :model="login"
                    :rules="rules"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol"
                  >
                    <a-form-model-item label="Activity type" prop="username">
                      <a-input
                        ref="userNameInput"
                        class="inputs"
                        size="large"
                        v-model="login.username"
                        placeholder="请输入账号"
                      >
                        <a-icon
                          slot="prefix"
                          type="user"
                          style="color: rgba(255, 70, 0, 1)"
                        />
                      </a-input>
                    </a-form-model-item>
                    <a-form-model-item label="Activity type" prop="password">
                      <a-input
                        ref="userNameInput"
                        class="inputs"
                        size="large"
                        type="password"
                        v-model="login.password"
                        placeholder="请输入密码"
                        @keyup.enter="onSubmit"
                      >
                        <a-icon
                          slot="prefix"
                          type="lock"
                          style="color: rgba(255, 70, 0, 1)"
                        />
                      </a-input>
                    </a-form-model-item>
                    <div class="tips">
                      <!-- <a-checkbox @change="changeps" :checked="rember">
                        记住密码
                      </a-checkbox> -->
                      <a class="activecolcor" @click="forget">忘记密码</a>
                    </div>
                  </a-form-model>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="验证码登录">
                <a-form-model
                  ref="mobile"
                  :model="mobile"
                  :rules="eRules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                >
                  <a-form-model-item label="Activity type" prop="phone">
                    <a-input
                      class="inputs"
                      size="large"
                      v-model="mobile.phone"
                      placeholder="请输入手机号"
                    >
                      <a-icon
                        slot="prefix"
                        type="user"
                        style="color: rgba(255, 70, 0, 1)"
                      />
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item label="Activity type" prop="code">
                    <a-input
                      class="inputs"
                      v-model="mobile.code"
                      size="large"
                      style="width: 60%"
                      placeholder="请输入验证码"
                    ></a-input>
                    <VerifiyCodeButton
                      style="margin-left: 3rem"
                      :mobile="mobile.phone"
                      modelType="登录"
                      @onSendMessage="getVerificationCode"
                    />
                  </a-form-model-item>
                </a-form-model>
              </a-tab-pane>
            </a-tabs>
            <a-button
              class="putBTN"
              type="primary"
              @click="onSubmit"
              :loading="loading"
            >
              登录
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PublicHeader from "@/components/PublicHeader";
import { LoginAPI } from "@/api/login.js";
import { tips } from "@/utils/tips.js";
import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapState } = createNamespacedHelpers("Public");
import Routerpush from "@/mixins/Routerpush";
import { rules } from "@/utils/rules.js";
import { codeRule } from "@/utils/rules.js";
import LocalCache from "@/utils/cache.js";
import VerifiyCodeButton from "@/components/verification-code-button";
export default {
  mixins: [Routerpush],
  data() {
    return {
      type: "1",
      imgs: require("@/assets/img/leftimg.png"),
      login: {
        username: "",
        password: "",
      },
      rember: false,
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      loading: false,
      // 校验规则
      rules: rules,
      eRules: codeRule,
      mobile: {
        phone: "",
        code: "",
      },

      // 登陆模式
      loginMode: 1,
    };
  },
  components: {
    PublicHeader,
    VerifiyCodeButton,
  },

  computed: {
    ...mapState(["passList"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  mounted() {},

  methods: {
    ...mapMutations(["setToken", "remberPs"]),

    onSubmit() {
      if (this.loginMode === "2") {
        console.log("dfasfasd");
        this.codeLogin();
      } else {
        this.userNameLogin();
      }
    },

    // 验证码登陆
    codeLogin() {
      this.$refs.mobile.validate((valid) => {
        if (valid) {
          this.loading = true;
          LoginAPI.loginCode(this.mobile).then((res) => {
            console.log(res);
            this.loading = false;
            let that = this;
            if (res.success) {
              tips(res, "登录");
              this.setToken(res.data.token);
              LocalCache.setCache("token", res.data.token);
              setTimeout(function () {
                that.$router.push("/");
              }, 500);
            }
          });
        }
      });
    },

    // 账号登陆
    userNameLogin() {
      this.$refs.login.validate((valid) => {
        if (valid) {
          this.loading = true;
          LoginAPI.login(this.login).then((res) => {
            console.log(res);
            this.loading = false;
            let that = this;
            if (res.success) {
              tips(res, "登录");
              this.setToken(res.data.token);
              LocalCache.setCache("token", res.data.token);
              setTimeout(function () {
                that.$router.push("/");
              }, 500);
            }
          });
        }
      });
    },
    forget() {
      this.$router.push("/editsPassword");
    },

    // 获取验证码
    getVerificationCode() {
      console.log("发送验证码");
      LoginAPI.getCode(this.mobile.phone).then((res) => {
        console.log(res);
      });
    },

    // 切换模式登陆
    handleTab(val) {
      console.log(val);
      this.loginMode = val;
    },

    changeps(e) {
      this.rember = e.target.checked;
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.BG {
  width: 100%;
  height: 70%;
  background-image: linear-gradient(
    to right,
    rgba(254, 132, 50, 1),
    rgba(249, 70, 57, 1)
  );
  a {
    color: #333;
  }
  background-size: cover;
  .logos {
    width: 570px;
    height: 600px;
    margin-top: 5%;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    .box {
      position: absolute;
      right: 10px;
      top: 10%;
      width: 480px;
      height: 480px;
      background: #fff;
      padding: 32px;
      box-sizing: border-box;
      border-radius: 10px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .body {
        font-size: 30px;
        color: #333;
      }
    }
    .login-box {
      margin-top: 10px;
      & > * {
        margin-bottom: 20px;
      }
      .inputs {
        height: 40px;
        line-height: 40px;
      }
      .tips {
        text-align: right;
        // display: flex;
        // justify-content: space-between;
      }
      .sendmsg {
        //    & > *{border-radius: 0px;}
        .sendcode {
          margin-left: 10px;
        }
      }
    }
    .putBTN {
      background-image: linear-gradient(
        to right,
        rgba(254, 132, 50, 1),
        rgba(249, 70, 57, 1)
      );
      width: calc(100% - 64px);
      height: 40px;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      color: #fff;
      position: absolute;
      left: 32px;
      bottom: 80px;
    }
  }
}
.btnPos {
  display: flex;
}
</style>
